/** php端接口 (原有接口调用方式太繁琐，从3.7.3版本起采用此方法调用)*/
import { phpBaseUrl } from "@/utils/anyUrl.js";
import request from "@/utils/request.js";
var BaseUrl = phpBaseUrl;

// 上传图片
export function uploadImage(data) {
  return request({
    url: BaseUrl + "/basis/upload",
    method: "post",
    data,
  });
}

/** 结算中心************ */
// 业务员佣金（万通）-业务员佣金打款记录列表
export function salesmanlist(params) {
  return request({
    url: BaseUrl + `/salesmanCommisionProof/list`,
    method: "get",
    params,
  });
}
// 业务员佣金（万通）-业务员佣金打款记录导出
export function salesmanlistExport(params) {
  return request({
    url: BaseUrl + `/salesmanCommisionProof/listExport`,
    method: "get",
    params,
  });
}
// 业务员佣金（万通）-业务员佣金明细
export function salesmandetail(params) {
  return request({
    url: BaseUrl + `/salesmanCommisionProof/detail`,
    method: "get",
    params,
  });
}
// 平台提现记录-列表
export function platformWithdrawRecordlist(data) {
  return request({
    url: BaseUrl + `/platformWithdrawRecord/list`,
    method: "post",
    data,
  });
}
/** 会员中心************ */
// 会员列表-切换平台资质类型
export function togglePlatformQualification(data) {
  return request({
    url: BaseUrl + `/User/togglePlatformQualification`,
    method: "post",
    data,
  });
}
// 会员中心-切换会员经营范围是否同步
export function switchSyncstatus(data) {
  return request({
    url: BaseUrl + `/user/switchSyncstatus`,
    method: "post",
    data,
  });
}
// 会员中心-手动添加客户列表
export function manuallyCustomerlist(data) {
  return request({
    url: BaseUrl + `/manuallyCustomer/list`,
    method: "post",
    data,
  });
}
// 会员中心-手动删除客户列表
export function manuallyCustomerdel(data) {
  return request({
    url: BaseUrl + `/manuallyCustomer/del`,
    method: "post",
    data,
  });
}
// 会员详情-连锁总部名称是否已存在
export function isExistChainHeadquarter(params) {
  return request({
    url: BaseUrl + `/User/isExistChainHeadquarter`,
    method: "get",
    params,
  });
}
// 会员列表-删除打卡地址
export function delClockInAddress(data) {
  return request({
    url: BaseUrl + `/user/delClockInAddress`,
    method: "post",
    data,
  });
}
// 会员列表-审核
export function Customeraudit(data) {
  return request({
    url: BaseUrl + `/manuallyCustomer/audit`,
    method: "post",
    data,
  });
}

/** 供应商中心************ */
// 【接口PHP】供应商列表-获取复核周期
export function getReviewPeriod(params) {
  return request({
    url: BaseUrl + `/businessInfo/getReviewPeriod`,
    method: "get",
    params,
  });
}
// 【接口PHP】供应商列表-设置复核周期
export function setReviewPeriod(data) {
  return request({
    url: BaseUrl + `/businessInfo/setReviewPeriod`,
    method: "post",
    data,
  });
}
// 【接口PHP】供应商列表-获取日志类型
export function businessOptType(params) {
  return request({
    url: BaseUrl + `/businessInfo/businessOptType`,
    method: "get",
    params,
  });
}
// 【接口PHP】供应商列表-操作日志
export function businessOptLog(params) {
  return request({
    url: BaseUrl + `/businessInfo/businessOptLog`,
    method: "get",
    params,
  });
}
// 【接口PHP】供应商列表-复核记录
export function reviewRecordList(params) {
  return request({
    url: BaseUrl + `/businessInfo/reviewRecordList`,
    method: "get",
    params,
  });
}
// 【接口PHP】供应商列表-复核记录
export function addReviewRecord(data) {
  return request({
    url: BaseUrl + `/businessInfo/addReviewRecord`,
    method: "post",
    data,
  });
}
// 【接口PHP】供应商列表-复核记录详情
export function reviewRecordDetail(params) {
  return request({
    url: BaseUrl + `/businessInfo/reviewRecordDetail`,
    method: "get",
    params,
  });
}
// 【接口PHP】供应商列表-分账提现周期查询
export function withdrawCycle(params) {
  return request({
    url: BaseUrl + `/businessInfo/withdrawCycle`,
    method: "get",
    params,
  });
}

/** 白条管理************ */
// 保理白条客户授信记录列表
export function factoringCreditlist(params) {
  return request({
    url: BaseUrl + `/factoringCredit/list`,
    method: "get",
    params,
  });
}
// 授信客户详情
export function factoringCreditdetail(params) {
  return request({
    url: BaseUrl + `/factoringCredit/detail`,
    method: "get",
    params,
  });
}
// 补充资料
export function factoringCreditreplenish(data) {
  return request({
    url: BaseUrl + `/factoringCredit/replenish`,
    method: "post",
    data,
  });
}
// 作废申请
export function factoringCreditcancel(data) {
  return request({
    url: BaseUrl + `/factoringCredit/cancel`,
    method: "post",
    data,
  });
}
//获取征信授权书等签约地址
export function getSignUrl(params) {
  return request({
    url: BaseUrl + `/factoringCredit/getSignUrl`,
    method: "get",
    params,
  });
}
//获取进件审核状态下拉列表
export function getStatusDropDown(params) {
  return request({
    url: BaseUrl + `/factoringCredit/getStatusDropDown`,
    method: "get",
    params,
  });
}
//获取保理进件资料
export function obtainFactoringAccountInfo(params) {
  return request({
    url: BaseUrl + `/factoringCredit/obtainFactoringAccountInfo`,
    method: "get",
    params,
  });
}

/** 对账管理************ */
// 白条对账单列表
export function billList(params) {
  return request({
    url: BaseUrl + `/factoring/billList`,
    method: "get",
    params,
  });
}
//三方对账
//三方对账-列表
export function statementList(params) {
  return request({
    url: BaseUrl + `/threePartiesStatement/statementList`,
    method: "get",
    params,
  });
}
//三方对账-手动解冻
export function unfreeze(data) {
  return request({
    url: BaseUrl + `/threePartiesStatement/unfreeze`,
    method: "post",
    data,
  });
}

/** 商品中心************ */
// 商品资料库-商品资料详情
export function platformGoodsdetail(params) {
  return request({
    url: BaseUrl + "/goods/platformGoods/detail",
    method: "get",
    params,
  });
}
// 商品资料库-商品资料更新图片
export function updatePicture(data) {
  return request({
    url: BaseUrl + "/goods/platformGoods/updatePicture",
    method: "post",
    data,
  });
}
// 禁售字库-列表
export function prohibitedlist(params) {
  return request({
    url: BaseUrl + "/goods/prohibited/list",
    method: "get",
    params,
  });
}
// 禁售字库-创建
export function prohibitedadd(data) {
  return request({
    url: BaseUrl + "/goods/prohibited/add",
    method: "post",
    data,
  });
}
// 禁售字库-状态切换
export function prohiStatus(data) {
  return request({
    url: BaseUrl + "/goods/prohibited/changeStatus",
    method: "post",
    data,
  });
}
// 禁售字库-删除
export function prohidelete(data) {
  return request({
    url: BaseUrl + "/goods/prohibited/delete",
    method: "post",
    data,
  });
}
// 禁售字禁售类型下拉列表
export function typeList(params) {
  return request({
    url: BaseUrl + "/goods/prohibited/typeList",
    method: "get",
    params,
  });
}
// 商品资料库商品搜索
export function platsearch(params) {
  return request({
    url: BaseUrl + "/goods/platformGoods/search",
    method: "get",
    params,
  });
}
// 修改关联资料库商品
export function modifyPlatformGoods(data) {
  return request({
    url: BaseUrl + "/goods/goodsInfo/modifyPlatformGoods",
    method: "post",
    data,
  });
}


/** 审核中心************ */
// 商品资料审核列表
export function platformGoodsAuditlist(params) {
  return request({
    url: BaseUrl + "/goods/platformGoodsAudit/list",
    method: "get",
    params,
  });
}
// 商品资料审核详情
export function platformGoodsAuditdetail(params) {
  return request({
    url: BaseUrl + "/goods/platformGoodsAudit/detail",
    method: "get",
    params,
  });
}
// 商品资料审核
export function platformGoodsAuditaudit(data) {
  return request({
    url: BaseUrl + "/goods/platformGoodsAudit/audit",
    method: "post",
    data,
  });
}
// 商品文件审核列表
export function getGoodsImportRecordList(params) {
  return request({
    url: BaseUrl + "/goods/platformGoods/getGoodsImportRecordList",
    method: "get",
    params,
  });
}
// 商品审核编辑-审核明细列表
export function getGoodsImportAuditList(params) {
  return request({
    url: BaseUrl + "/goods/platformGoods/getGoodsImportAuditList",
    method: "get",
    params,
  });
}
// 商品审核编辑-审核状态切换
export function goodsImportAuditToggle(data) {
  return request({
    url: BaseUrl + "/goods/platformGoods/goodsImportAuditToggle",
    method: "post",
    data,
  });
}
// 商品审核编辑-替换状态切换（图片或说明书）
export function goodsImportAuditReplaceGoodsImage(data) {
  return request({
    url: BaseUrl + "/goods/platformGoods/goodsImportAuditReplaceGoodsImage",
    method: "post",
    data,
  });
}
// 商品审核编辑-获取对码商品列表
export function getGoodsImportAuditGoodsList(params) {
  return request({
    url: BaseUrl + "/goods/platformGoods/getGoodsImportAuditGoodsList",
    method: "get",
    params,
  });
}
// 商品审核编辑-获取商品详情
export function getGoodsImportAuditGoodsDetail(params) {
  return request({
    url: BaseUrl + "/goods/platformGoods/getGoodsImportAuditGoodsDetail",
    method: "get",
    params,
  });
}
// 商品审核编辑-保存
export function saveGoodsImportAudit(data) {
  return request({
    url: BaseUrl + "/goods/platformGoods/saveGoodsImportAudit",
    method: "post",
    data,
  });
}
// 商品审核编辑-更新对码
export function goodsImportAuditGoodsCode(data) {
  return request({
    url: BaseUrl + "/goods/platformGoods/goodsImportAuditGoodsCode",
    method: "post",
    data,
  });
}
// 商品审核编辑-审核提交
export function goodsImportAuditSubmit(data) {
  return request({
    url: BaseUrl + "/goods/platformGoods/goodsImportAuditSubmit",
    method: "post",
    data,
  });
}
// 商品文件审核详情
export function getGoodsImportAuditGoods(params) {
  return request({
    url: BaseUrl + "/goods/platformGoods/getGoodsImportAuditGoods",
    method: "get",
    params,
  });
}
// 会员审核-获取代理人信息
export function getAgentInfo(params) {
  return request({
    url: BaseUrl + "/userQualification/memberAudit/getAgentInfo",
    method: "get",
    params,
  });
}
// 会员审核-经营范围设置发送短信
export function sendSms(data) {
  return request({
    url: BaseUrl + "/userQualification/memberAudit/sendSms",
    method: "post",
    data,
  });
}
// 会员审核-是否腾讯电子签
export function isTencent(params) {
  return request({
    url: BaseUrl + "/userQualification/memberAudit/isTencent",
    method: "get",
    params,
  });
}
// 会员审核-审核通过提交
export function passReview(data) {
  return request({
    url: BaseUrl + "/userQualification/memberAudit/passReview",
    method: "post",
    data,
  });
}
// 获取经营类别
export function basisscope(params) {
  return request({
    url: BaseUrl + "/basis/scope",
    method: "get",
    params,
  });
}
// 获取商品分类
export function categoryList(params) {
  return request({
    url: BaseUrl + "/goods/category/list",
    method: "get",
    params,
  });
}

/** 基础设置************ */
// 全局配置-举报电话获取
export function getValue(params) {
  return request({
    url: BaseUrl + "/config/baseConfig/getValue",
    method: "get",
    params,
  });
}
// 全局配置-举报电话设置
export function setValue(data) {
  return request({
    url: BaseUrl + "/config/baseConfig/setValue",
    method: "post",
    data,
  });
}
// 全局配置-支付配置-支付方式列表
export function paymentTypelist(params) {
  return request({
    url: BaseUrl + "/paymentType/list",
    method: "get",
    params,
  });
}
// 全局配置-支付配置-支付方式详情
export function paymentTypedetail(params) {
  return request({
    url: BaseUrl + "/paymentType/detail",
    method: "get",
    params,
  });
}
// 全局配置-支付配置-支付方式编辑
export function paymentTypeedit(data) {
  return request({
    url: BaseUrl + "/paymentType/edit",
    method: "post",
    data,
  });
}

// 协议中心-协议启用状态切换
export function changeStatus(data) {
  return request({
    url: BaseUrl + "/agreement/changeStatus",
    method: "post",
    data,
  });
}
// 全局配置-支付配置-修改支付方式名称
export function modifyName(data) {
  return request({
    url: BaseUrl + "/paymentType/modifyName",
    method: "post",
    data,
  });
}
// 协议中心-协议确认记录
export function record(params) {
  return request({
    url: BaseUrl + "/agreement/record",
    method: "get",
    params,
  });
}
// 协议中心-全局配置电子签章获取
export function getESign(params) {
  return request({
    url: BaseUrl + "/config/baseConfig/getESign",
    method: "get",
    params,
  });
}
// 协议中心-全局配置电子签章设置
export function setESign(data) {
  return request({
    url: BaseUrl + "/config/baseConfig/setESign",
    method: "post",
    data,
  });
}
